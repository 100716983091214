var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"end"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.isVisibleDialog),callback:function ($$v) {_vm.isVisibleDialog=$$v},expression:"isVisibleDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-4"},[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.product.name))])]),_c('v-card-text',{staticClass:"pa-0 grey lighten-4"},[_c('v-container',[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-text',[_c('v-form',{ref:"addHubForm",attrs:{"lazy-validation":""},model:{value:(_vm.is_valid),callback:function ($$v) {_vm.is_valid=$$v},expression:"is_valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{staticClass:"pt-0",attrs:{"clearable":"","items":_vm.listHubs,"item-text":"name","item-value":"id","label":_vm.$t('hub'),"loading":_vm.isLoadingHubs,"search-input":_vm.search_hub,"rules":[_vm.rules.required]},on:{"update:searchInput":function($event){_vm.search_hub=$event},"update:search-input":function($event){_vm.search_hub=$event}},model:{value:(_vm.hubToAdd.hub_id),callback:function ($$v) {_vm.$set(_vm.hubToAdd, "hub_id", $$v)},expression:"hubToAdd.hub_id"}})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"auto","outlined":"","label":_vm.$t('min_qte'),"type":"number","rules":[_vm.rules.required]},model:{value:(_vm.hubToAdd.min_qte_in_hub),callback:function ($$v) {_vm.$set(_vm.hubToAdd, "min_qte_in_hub", $$v)},expression:"hubToAdd.min_qte_in_hub"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"auto","outlined":"","label":_vm.$t('max_qte'),"type":"number","rules":[
                        _vm.rules.min_quantity(
                          1 + Number(_vm.hubToAdd.min_qte_in_hub)
                        ),
                      ]},model:{value:(_vm.hubToAdd.max_qte_in_hub),callback:function ($$v) {_vm.$set(_vm.hubToAdd, "max_qte_in_hub", $$v)},expression:"hubToAdd.max_qte_in_hub"}})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"auto","outlined":"","label":_vm.$t('days_before_expiry'),"type":"number","rules":[_vm.rules.required]},model:{value:(_vm.hubToAdd.days_before_expiry),callback:function ($$v) {_vm.$set(_vm.hubToAdd, "days_before_expiry", $$v)},expression:"hubToAdd.days_before_expiry"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.addHub(_vm.hubToAdd)}}},[_vm._v(_vm._s(_vm.$t("validate")))])],1)],1)],1),_c('v-row',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.is_valid),callback:function ($$v) {_vm.is_valid=$$v},expression:"is_valid"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.hubsHeader,"items":_vm.product.hubs,"hide-default-footer":"","dense":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.min_qte_in_hub",fn:function({ item }){return [_c('v-text-field',{staticClass:"my-1",attrs:{"hide-details":"auto","outlined":"","dense":"","type":"number","rules":item.alert_status == 'active'
                              ? [_vm.rules.min_quantity(0)]
                              : [],"disabled":item.alert_status == 'inactive'},model:{value:(item.min_qte_in_hub),callback:function ($$v) {_vm.$set(item, "min_qte_in_hub", $$v)},expression:"item.min_qte_in_hub"}})]}},{key:"item.max_qte_in_hub",fn:function({ item }){return [_c('v-text-field',{staticClass:"my-1",attrs:{"hide-details":"auto","outlined":"","dense":"","type":"number","rules":item.alert_status == 'active'
                              ? [
                                  _vm.rules.min_quantity(
                                    1 + Number(item.min_qte_in_hub)
                                  ),
                                ]
                              : [],"disabled":item.alert_status == 'inactive'},model:{value:(item.max_qte_in_hub),callback:function ($$v) {_vm.$set(item, "max_qte_in_hub", $$v)},expression:"item.max_qte_in_hub"}})]}},{key:"item.days_before_expiry",fn:function({ item }){return [_c('v-text-field',{staticClass:"my-1",attrs:{"hide-details":"auto","outlined":"","dense":"","type":"number","rules":item.alert_status == 'active'
                              ? [_vm.rules.min_quantity(0)]
                              : [],"disabled":item.alert_status == 'inactive'},model:{value:(item.days_before_expiry),callback:function ($$v) {_vm.$set(item, "days_before_expiry", $$v)},expression:"item.days_before_expiry"}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"false-value":"inactive","true-value":"active","hide-details":""},model:{value:(item.alert_status),callback:function ($$v) {_vm.$set(item, "alert_status", $$v)},expression:"item.alert_status"}})]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("empty"))+" ")]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('small',[_vm._v(_vm._s(_vm.$t("indicates_required_field")))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.is_loading_save,"disabled":!_vm.is_valid},on:{"click":function($event){return _vm.saveForm(_vm.product)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }