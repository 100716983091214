<template>
  <v-row justify="end">
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-card-title class="pb-4">
          <span class="text-h3">{{ product.name }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-card elevation="5">
              <v-card-text>
                <v-form
                  ref="addHubForm"
                  v-model="is_valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-autocomplete
                        class="pt-0"
                        clearable
                        v-model="hubToAdd.hub_id"
                        :items="listHubs"
                        item-text="name"
                        item-value="id"
                        :label="$t('hub')"
                        :loading="isLoadingHubs"
                        :search-input.sync="search_hub"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      class="px-0"
                      cols="12"
                      sm="2"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('min_qte')"
                        type="number"
                        v-model="hubToAdd.min_qte_in_hub"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('max_qte')"
                        type="number"
                        v-model="hubToAdd.max_qte_in_hub"
                        :rules="[
                          rules.min_quantity(
                            1 + Number(hubToAdd.min_qte_in_hub)
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="px-0"
                      cols="12"
                      sm="2"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('days_before_expiry')"
                        type="number"
                        v-model="hubToAdd.days_before_expiry"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                    >
                      <v-btn
                        block
                        outlined
                        color="primary"
                        @click="addHub(hubToAdd)"
                        >{{ $t("validate") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-form
                    ref="form"
                    v-model="is_valid"
                    lazy-validation
                  >
                    <v-col cols="12">
                      <v-data-table
                        :headers="hubsHeader"
                        :items="product.hubs"
                        hide-default-footer
                        dense
                        :items-per-page="-1"
                      >
                        <template v-slot:item.min_qte_in_hub="{ item }">
                          <v-text-field
                            class="my-1"
                            hide-details="auto"
                            outlined
                            dense
                            type="number"
                            v-model="item.min_qte_in_hub"
                            :rules="
                              item.alert_status == 'active'
                                ? [rules.min_quantity(0)]
                                : []
                            "
                            :disabled="item.alert_status == 'inactive'"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.max_qte_in_hub="{ item }">
                          <v-text-field
                            class="my-1"
                            hide-details="auto"
                            outlined
                            dense
                            type="number"
                            v-model="item.max_qte_in_hub"
                            :rules="
                              item.alert_status == 'active'
                                ? [
                                    rules.min_quantity(
                                      1 + Number(item.min_qte_in_hub)
                                    ),
                                  ]
                                : []
                            "
                            :disabled="item.alert_status == 'inactive'"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.days_before_expiry="{ item }">
                          <v-text-field
                            class="my-1"
                            hide-details="auto"
                            outlined
                            dense
                            type="number"
                            v-model="item.days_before_expiry"
                            :rules="
                              item.alert_status == 'active'
                                ? [rules.min_quantity(0)]
                                : []
                            "
                            :disabled="item.alert_status == 'inactive'"
                          ></v-text-field>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-checkbox
                            v-model="item.alert_status"
                            false-value="inactive"
                            true-value="active"
                            hide-details
                            class="shrink mr-2 mt-0"
                          ></v-checkbox>
                        </template>
                        <template v-slot:no-data>
                          {{ $t("empty") }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-form>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveForm(product)"
            :loading="is_loading_save"
            :disabled="!is_valid"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
import debounce from "lodash/debounce";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",
      listHubs: "hubs/activeHubs",
      productsQueryParams: "products/params",
    }),
    isVisibleDialog: {
      get() {
        return this.$store.state.products.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_DIALOG", value);
      },
    },
    product: {
      get() {
        return this.$store.state.products.product;
      },
      set(value) {
        this.$store.commit("products/SET_PRODUCT", value);
      },
    },

    productHubs: {
      get() {
        return this.$store.state.products.product.hubs || [];
      },
      set(value) {
        this.$store.commit("products/EDITED_HUB", value);
      },
    },
  },

  watch: {
    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 100,
        for: "alerts",
        search,
      });
    }, 1000),
  },

  data() {
    return {
      is_valid: false,
      is_loading_save: false,
      search_hub: "",
      hubToAdd: {},

      hubsHeader: [
        {
          text: this.$t("actions"),
          align: "start",
          sortable: false,
          value: "actions",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("qte"),
          align: "start",
          sortable: false,
          value: "qte_in_hub",
        },
        {
          text: this.$t("min_qte"),
          align: "start",
          sortable: false,
          value: "min_qte_in_hub",
        },
        {
          text: this.$t("max_qte"),
          align: "start",
          sortable: false,
          value: "max_qte_in_hub",
        },
        {
          text: this.$t("days_before_expiry"),
          value: "days_before_expiry",
          align: "center",
          sortable: false,
          width: "15%",
        },
      ],

      productVariantsHeaders: [
        {
          text: this.$t("checkbox"),
          value: "checkbox",
          align: "start",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("code"),
          align: "start",
          sortable: false,
          value: "code",
          width: "15%",
        },

        {
          text: this.$t("sku"),
          value: "sku",
          align: "center",
          sortable: false,
          width: "20%",
        },

        {
          text: this.$t("purchase_price_ht"),
          value: "purchase_price_ht",
        },
        {
          text: this.$t("purchase_tva"),
          value: "purchase_tva",
        },

        {
          text: this.$t("price_ht"),
          value: "price_ht",
        },
        {
          text: this.$t("tva"),
          value: "tva",
        },
      ],
    };
  },

  methods: {
    closeForm() {
      this.$store.dispatch("products/closeForm");
      this.$refs.form.reset();
      this.is_loading_save = false;
    },

    async saveForm(product) {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading_save = true;
          if (product.id) {
            await this.$store.dispatch("productAlerts/attachAlerts", product);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          }
          await this.$store.dispatch("products/root", this.productsQueryParams);
          await this.$store.dispatch("badges/getProductsAlert", "stock");
          await this.$store.dispatch("badges/getProductsAlert", "expiry");
          this.is_loading_save = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error);
          this.is_loading_save = false;
        }
        this.is_loading_save = false;
      }
    },

    addHub(hub_with_mq) {
      if (this.$refs.addHubForm.validate()) {
        hub_with_mq.alert_status = "active";
        const hubQ = this.productHubs.find(
          (item) => item.id == hub_with_mq.hub_id
        );

        if (hubQ) {
          hubQ.min_qte_in_hub = Number(hub_with_mq.min_qte_in_hub);
          hubQ.max_qte_in_hub = Number(hub_with_mq.max_qte_in_hub);
          hubQ.days_before_expiry = Number(hub_with_mq.days_before_expiry);
          hubQ.alert_status = hub_with_mq.alert_status;
        } else {
          const hub = this.listHubs.find(
            (item) => item.id === hub_with_mq.hub_id
          );

          if (hub) {
            hub_with_mq.id = hub.id;
            hub_with_mq.name = hub.name;
            hub_with_mq.qte_in_hub = 0;
            const hubsCopy = [...this.productHubs];
            hubsCopy.push(hub_with_mq);
            this.productHubs = hubsCopy;
          }
        }
        this.hubToAdd = {
          min_qte_in_hub: 0,
        };
      }
      this.$refs.addHubForm.reset();
    },
  },
};
</script>
